<template>
    <div class="w-100">
        <div v-if="order.egleStatus !== 'On Hold'">
            <div v-if="allowPhotoChange">
                <div class="photo-replacement-notice" v-if="!dialogueOpen">
                    <div class="row">
                        <div class="col-xl-9 col-12">
                            <div style="padding: 10px 0">
                                <span class="camera-icon">📸</span>
                                <span v-if="order.allowFreePhotoChange" data-cy="free-photo-change"
                                >We’re waiting for a new photo from you!</span
                                >
                                <span v-else-if="order.allowPaidPhotoChange" data-cy="paid-photo-change"
                                >Payment successful! Please upload your photo
                                now</span
                                >
                            </div>
                        </div>
                        <div class="col-xl-3 col-12">
                            <a
                                @click.prevent="openDialogue"
                                class="btn btn-sm btn-tmr button-open-dialogue"
                            >UPLOAD PHOTO</a
                            >
                        </div>
                    </div>
                </div>
                <div class="photo-replacement-dialogue" v-if="dialogueOpen">
                    <PhotoReplacementDialogue
                        @close="closeDialogue"
                        :order="order"
                    ></PhotoReplacementDialogue>
                </div>
            </div>
            <div v-if="!allowPhotoChange && order.paidPhotoChangesAllowed > 0">
                <div class="photo-replacement-notice" v-if="!dialogueOpen">
                    <div class="row">
                        <div class="col-xl-9 col-12">
                            <div style="padding: 10px 0">
                                <span class="camera-icon">📸</span>
                                <span>We’re waiting for a new photo from you!</span>
                            </div>
                        </div>
                        <div class="col-xl-3 col-12">
                            <a
                                @click.prevent="getPhotoReplacementCheckoutLink"
                                class="btn btn-sm btn-tmr button-open-dialogue"
                            >UPLOAD PHOTO</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            &nbsp;
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { useOrderStore } from "@/stores/order";
import PhotoReplacementDialogue from "@/components/photo-replacement/PhotoReplacementDialogue.vue";

export default defineComponent({
    name: "PhotoReplacementNotice",
    components: { PhotoReplacementDialogue },
    props: ["order"],
    data() {
        return {
            dialogueOpen: false,
            orderStore: useOrderStore(),
        };
    },
    computed: {
        allowPhotoChange() {
            return (
                this.order.allowFreePhotoChange ||
                this.order.allowPaidPhotoChange
            );
        },
    },
    methods: {
        openDialogue() {
            this.dialogueOpen = true;
        },
        closeDialogue() {
            this.dialogueOpen = false;
        },
        getPhotoReplacementCheckoutLink() {
            this.$Progress.start();

            this.orderStore
                .getPictureUpdateCheckout({ record_id: this.order.id })
                .then((res) => {
                    this.$Progress.finish();
                    window.location.replace(res.data);
                });
        },
    },
});
</script>

<style scoped lang="scss">
.photo-replacement-notice {
    font-family: Helvetica, Inter, Roboto, Arimo, "Nimbus Sans", sans-serif;
    font-size: 15px;
    line-height: 24px;
    font-weight: bold;
    min-height: 43px;
    padding: 10px 8px;
    margin: 15px 0;
    background: #f7f7f8;
    border: 2px solid #f2f2f4;
    border-radius: 3px;

    a.btn {
        font-weight: bold;
        margin-top: 10px;
        width: 100%;

        @media (min-width: 1200px) {
            float: right;
            width: auto;
        }
    }

    span {
        height: 24px;
        vertical-align: middle;
    }

    .camera-icon {
        font-size: 24px;
        line-height: 24px;
        height: auto;
        padding: 15px 10px 10px;
        background: #efeff3;
        border-radius: 3px;
        vertical-align: middle;
        margin-right: 10px;

        @media (max-width: 768px) {
            width: 100%;
            display: block;
            text-align: center;
            margin-bottom: 10px;
        }
    }
}

.photo-replacement-dialogue {
    padding: 5px 10px;
    margin: 15px 0;
}
</style>
