import { defineStore } from "pinia";
import api from "@/lib/axios/http-client";

export const useCartStore = defineStore("cart", {
    state: () => {
        return {
            order: null,
            upgrades: null,
        };
    },
    getters: {
        cart(state) {
            let upgrades = state.upgrades; // || JSON.parse(window.localStorage.getItem("cart.upgrades"));
            let order = state.order; // || JSON.parse(window.localStorage.getItem("cart.order"));

            return { upgrades, order };
        },
    },
    actions: {
        addToCart(order, upgrades) {
            // window.localStorage.setItem("cart.order", JSON.stringify(order));
            // window.localStorage.setItem("cart.cart", JSON.stringify(upgrades));

            this.order = order;
            this.upgrades = upgrades;
        },
        getAmount() {
            return (
                0 +
                (this.upgrades?.frame?.amount || 0) +
                (this.upgrades?.size?.amount || 0)
            );
        },
        handleCheckout() {
            return api.client.post("/api/v1/order/upgrade", {
                record_id: this.order.id,
                upgrades: this.upgrades,
            });
        },
        clearCart() {
            this.order = null;
            this.upgrades = null;
        },
    },
});
