<template>
    <div>
        <LoadingSpinner v-if="isLoading"></LoadingSpinner>
        <div v-if="!isLoading">
            <HeaderPartial></HeaderPartial>
            <div id="order_page_content">
                <div class="container">
                    <div class="grid-row order_status_text">
                        <h2>Order status</h2>
                    </div>
                    <!----------------------------- PROGRESS BAR HTML ----------------------------->
                    <ProgressBar></ProgressBar>
                    <!----------------------------- PROGRESS BAR HTML END ----------------------------->

                    <!----------------------------- CONTROL CONTENT HTML START ----------------------------->

                    <div id="control_content">
                        <div
                            class="content left-side"
                            id="products_information"
                        >
                            <div class="block_padding">
                                <div class="row">
                                    <div class="col col-12">
                                        <h2 class="order_id">
                                            {{
                                                orderStore.order_data?.customer_data?.orderID?.toUpperCase()
                                            }}
                                        </h2>
                                    </div>
<!--                                    <div class="col col-5 text-right">-->
                                        <!--                                        <h2>-->
                                        <!--                                            {{-->
                                        <!--                                                currencySymbol(-->
                                        <!--                                                    orderStore.order_data-->
                                        <!--                                                        ?.order_currency-->
                                        <!--                                                )-->
                                        <!--                                            }}{{-->
                                        <!--                                                round(-->
                                        <!--                                                    orderStore.order_data-->
                                        <!--                                                        ?.customer_data?.total_price,-->
                                        <!--                                                    2-->
                                        <!--                                                )-->
                                        <!--                                            }}-->
                                        <!--                                        </h2>-->
<!--                                    </div>-->
                                </div>
                                <div class="row">
                                    <div class="col col-7">
                                        <p>
                                            {{
                                                orderStore.order_data
                                                    ?.customer_data?.ordersDate
                                            }}
                                        </p>
                                    </div>
                                    <div class="col col-5 text-right">
                                        <!--                                        <p>-->
                                        <!--                                            {{-->
                                        <!--                                                orderStore.order_data?.customer_data-->
                                        <!--                                                    ?.total_items-->
                                        <!--                                            }}-->
                                        <!--                                            item(s)-->
                                        <!--                                        </p>-->
                                    </div>
                                </div>
                            </div>

                            <div class="products_block">
                                <div class="block_title block_padding">
                                    <h3>Summary</h3>
                                </div>

                                <!----------------------------- PRODUCTS LIST ----------------------------->
                                <div class="product_list">
                                    <Product
                                        v-for="order in orderStore.order_data
                                            ?.customer_data?.orders"
                                        v-bind:key="order.id"
                                        :order="order"
                                    >
                                    </Product>
                                </div>
                                <!----------------------------- PRODUCTS LIST END ----------------------------->
                                <div class="bottom_line block_padding"></div>
                            </div>
                        </div>

                        <StepsController
                            v-for="order in orderStore.order_data?.customer_data
                                ?.orders"
                            v-bind:key="order.id"
                            :order="order"
                        >
                        </StepsController>
                    </div>
                    <!----------------------------- CONTROL CONTENT HTML END ----------------------------->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Product from "@/components/Product.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import StepsController from "@/components/StepsController.vue";
import { useOrderStore } from "@/stores/order";
import { currencySymbol, round } from "@/utilities/currency";
import * as Sentry from "@sentry/vue";
import HeaderPartial from "@/views/_partials/HeaderPartial.vue";
import { useAuthStore } from "@/stores/auth";

export default {
    name: "OrderPageView",
    components: {
        HeaderPartial,
        LoadingSpinner,
        Product,
        ProgressBar,
        StepsController,
    },
    created() {
        this.$Progress.start();

        this.orderStore
            .getOrder()
            .then(() => {
                if (this.$route.query.lineitem) {
                    this.orderStore.changeSelectedProduct(
                        this.$route.query.lineitem
                    );
                    this.$router.replace({ query: { lineitem: undefined } });
                }

                this.isLoading = false;
                this.$Progress.finish();
            })
            .catch((e) => {
                this.$Progress.fail();

                if (e?.response?.status >= 500) {
                    Sentry.captureException(e);
                    this.$toast.add({
                        severity: "error",
                        summary: "Error",
                        detail: "There was a server error while loading your order, please try again later!",
                        life: 5000,
                    });
                } else {
                    this.authStore.logOut().then(() => {
                        this.$router.push({
                            name: "login",
                            query: {
                                redirect: this.$route.fullPath,
                            },
                        });
                    });
                }
            });
    },
    data: function () {
        return {
            customer_data: {},
            orders: [],
            isLoading: true,
            review: "active",
            selectedProduct: false,
            orderStore: useOrderStore(),
            authStore: useAuthStore(),
        };
    },
    computed: {
        step() {
            return this.orderStore.step;
        },
    },
    methods: {
        round,
        currencySymbol,
    },
};
</script>

<style scoped lang="scss">
#products_information {
    h2.order_id {
        overflow-wrap: anywhere;
        word-break: break-all;
        word-wrap: break-word;
    }
}
</style>
