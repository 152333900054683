<template>
    <div class="line-item-upgrade-section">
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <h2 data-cy="product-title">{{ order.product }}</h2>
                        <p class="subtitle">
                            Current format: {{ order.orderSize
                            }}<span v-if="!order.itemIsDigital"
                                >, {{ currentFrame }}</span
                            >
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="upgrade-controls" v-if="order.allowUpsells && order.egleStatus !== 'On Hold'" data-cy="upgrade-controls">
            <div class="row">
                <div class="col">
                    <div
                        v-if="size_options.length > 1"
                        class="size-upgrade-section"
                    >
                        <div class="row">
                            <div class="col">
                                <h3>UPGRADE SIZE</h3>
                            </div>
                        </div>
                        <div class="row options">
                            <div
                                class="col"
                                v-for="option in size_options"
                                :key="option.label"
                            >
                                <a
                                    @click.prevent="
                                        selectOption('size_options', option)
                                    "
                                    href="#"
                                    class="option"
                                    :class="{
                                        selected: option.selected,
                                        disabled: option.disabled,
                                    }"
                                >
                                    <p>{{ formatSize(option.size) }}</p>
                                    <p>
                                        {{
                                            !option.current
                                                ? formatSizePrice(option)
                                                : "Current"
                                        }}
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="frame_options.length > 0"
                        class="frame-upgrade-section"
                    >
                        <div class="row">
                            <div class="col">
                                <h3>UPGRADE FRAME</h3>
                            </div>
                        </div>
                        <div class="row options">
                            <div
                                class="col"
                                v-for="option in frame_options"
                                :key="option.label"
                            >
                                <a
                                    @click.prevent="
                                        selectOption('frame_options', option)
                                    "
                                    href="#"
                                    class="option"
                                    :class="{
                                        selected: option.selected,
                                        disabled: option.disabled,
                                    }"
                                >
                                    <p style="text-transform: capitalize">
                                        {{ option.label }}
                                    </p>
                                    <p>
                                        {{
                                            option.current &&
                                            getFrameUpgradeValue(option) === 0
                                                ? "Current"
                                                : formatFramePrice(option)
                                        }}
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        class="row upgrade-submit"
                        v-if="
                            frame_options.length > 0 || size_options.length > 0
                        "
                    >
                        <div class="col">
                            <a
                                @click.prevent="handleUpgrade"
                                href="#"
                                class="btn btn-tmr-gold"
                            >
                                UPGRADE -
                                {{ currencySymbol(currency) + sumTotal }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { toRaw } from "vue";
import { useOrderStore } from "@/stores/order";
import { useCartStore } from "@/stores/cart";
import { useAuthStore } from "@/stores/auth";
import { currencySymbol } from "@/utilities/currency";
import { capitalize } from "@/utilities/strings";
import * as Sentry from "@sentry/vue";
import {
    sizeIsPortrait,
    sizeToLandscape,
    sizeToPortrait,
} from "@/utilities/lineItemUtils";

export default {
    name: "LineItemUpgradeSection",
    props: {
        order: Object,
    },
    data() {
        return {
            orderStore: useOrderStore(),
            cartStore: useCartStore(),
            authStore: useAuthStore(),
            currency: "",
            size_options: [],
            frame_options: [],
            cart: {},
            updating: false,
        };
    },
    computed: {
        sumTotal() {
            let total = 0;
            let qty = this.order.quantity || 1;

            this.size_options.forEach((elm) => {
                if (elm.selected) {
                    let costOfOne = this.getSizeUpgradeValue(elm) || 0;
                    total += costOfOne * qty;
                }
            });

            let frameOption = this.findSelectedOrCurrent("frame_options");

            let costOfOne = frameOption ? this.getFrameUpgradeValue(frameOption) : 0;

            total += costOfOne * qty;

            return total.toFixed(2);
        },
        currentFrame() {
            let order_frame = this.order.frame ? this.order.frame.trim() : "";
            if (order_frame) {
                return capitalize(order_frame.toLowerCase());
            }

            let shopify_frame = this.orderStore.order_data.customer_data.shopify_frame
                ? this.orderStore.order_data.customer_data.shopify_frame.trim()
                : "";

            if (shopify_frame) {
                return capitalize(shopify_frame);
            }

            return "No Frame";
        },
    },
    mounted() {
        this.currency = this.orderStore.order_data.order_currency;

        this.setupOptions();
    },
    methods: {
        currencySymbol,
        setupOptions() {
            if (
                this.order.allowUpsells === false ||
                this.order.orderSize === "DIGITAL ONLY"
            ) {
                return false;
            }
            // sizes
            let availableSizeUpgrades =
                this.orderStore.order_data.available_upgrades.size_upgrades;
            if (availableSizeUpgrades) {
                for (let i = 0; i < availableSizeUpgrades.length; i++) {
                    let elm = Object.assign(
                        {},
                        availableSizeUpgrades[
                            availableSizeUpgrades.length - i - 1
                        ]
                    );

                    elm.current = false;
                    elm.selected = false;
                    elm.label = elm.size;

                    if (elm.size === sizeToPortrait(this.order.orderSize)) {
                        elm.current = true;
                        elm.selected = true;
                        this.size_options.unshift(elm);
                        break;
                    }

                    this.size_options.unshift(elm);
                }
            }

            // frames
            let currentFrame = this.order.frame ? this.order.frame.trim() : "";

            let frameUpgrades = Object.assign(
                {},
                this.orderStore.order_data.available_upgrades.frame
            );

            if (Object.values(frameUpgrades).flat(Infinity).length > 0) {
                if (!currentFrame) {
                    currentFrame = this.orderStore.order_data.customer_data.shopify_frame;
                }

                let frame_options = [];

                if (!currentFrame) {
                    frame_options[0] = {
                        label: "No Frame",
                        amount: 0,
                        currency: this.orderStore.order_data.order_currency,
                        selected: true,
                        disabled: false,
                        current: true,
                    };
                }

                let referentSize = sizeToPortrait(this.order.orderSize);

                Object.keys(frameUpgrades).forEach((key) => {
                    if (!frameUpgrades[key][referentSize]) {
                        return;
                    }

                    let newOption = {
                        label: key,
                        ...frameUpgrades[key][referentSize],
                    };

                    newOption.selected = currentFrame === newOption.label;

                    newOption.current = currentFrame === newOption.label;

                    frame_options.push(newOption);
                });

                if (frame_options.length > 1) {
                    this.frame_options = frame_options;
                }
            }
        },
        updateFrameOptions() {
            let frameUpgrades =
                this.orderStore.order_data.available_upgrades.frame;

            let referentSize =
                this.findSelectedOrCurrent("size_options")?.size ||
                sizeToPortrait(this.order.orderSize);

            for (let i = 0; i < this.frame_options.length; i++) {
                if (this.frame_options[i].label === "No Frame") {
                    continue;
                }

                this.frame_options[i].currency = frameUpgrades[this.frame_options[i].label][referentSize].currency;
                this.frame_options[i].amount = frameUpgrades[this.frame_options[i].label][referentSize].amount;
            }
        },
        formatSizePrice(option) {
            let value = this.getSizeUpgradeValue(option);

            if (value <= 0) {
                return currencySymbol(option.currency) + 0;
            }

            if (this.order.quantity > 1 && value > 0) {
                return "+ ( " + this.order.quantity + " x " + currencySymbol(option.currency) + value + " )";
            }

            return "+" + currencySymbol(option.currency) + value;
        },
        formatFramePrice(option) {
            let value = this.getFrameUpgradeValue(option);

            if (this.order.quantity > 1 && value > 0) {
                return "+ ( " + this.order.quantity + " x " + currencySymbol(option.currency) + value + " )";
            }

            return "+" + currencySymbol(option.currency) + value;
        },
        getSizeUpgradeValue(option) {
            let current = this.findCurrent("size_options");

            return option.amount - (current?.amount || 0);
        },
        getFrameUpgradeValue(option) {
            let currentSize =
                this.findCurrent("size_options")?.size || sizeToPortrait(this.order.orderSize);

            let currentFrame =
                (this.order.frame ? this.order.frame.trim() : "")
                    || this.orderStore.order_data.customer_data.shopify_frame
                    || null;

            if (currentFrame) {
                currentFrame = currentFrame.toLowerCase();
            }

            let currentFramePrice = currentFrame
                ? this.orderStore.order_data.available_upgrades.frame[
                    currentFrame
                ][currentSize]?.amount || 0
                : 0;

            let upgradeValue = (option.amount || 0) - currentFramePrice;

            if (upgradeValue < 0) {
                return 0;
            }

            return upgradeValue;
        },
        findCurrent(collection) {
            return this[collection]?.find((elm) => elm.current);
        },
        findSelected(collection) {
            return this[collection]?.find((elm) => elm.selected);
        },
        findSelectedOrCurrent(collection) {
            let selected = this[collection]?.find((elm) => elm.selected);

            if (selected) {
                return selected;
            }

            return this[collection]?.find((elm) => elm.current);
        },
        selectOption(collection, option) {
            this[collection].forEach((elm) => {
                elm.selected = elm.label === option.label;
            });

            this.updateFrameOptions();
        },
        handleUpgrade() {
            if (this.updating) {
                return false;
            }

            this.updating = true;

            let currentSize = toRaw(this.findCurrent("size_options"));
            let currentFrame = toRaw(this.findCurrent("frame_options"));
            let selectedSize = toRaw(this.findSelected("size_options"));
            let selectedFrame = toRaw(this.findSelected("frame_options"));

            let cart = {};

            if (selectedSize && selectedSize.label !== currentSize.label) {
                cart["size"] = Object.assign({}, selectedSize);
                cart["size"].amount = this.getSizeUpgradeValue(selectedSize);
            }

            if (
                selectedFrame &&
                (selectedFrame.label !== currentFrame.label ||
                    this.getFrameUpgradeValue(selectedFrame) > 0)
            ) {
                cart["frame"] = Object.assign({}, selectedFrame);
                cart["frame"].amount = this.getFrameUpgradeValue(selectedFrame);
            }

            if (!cart["size"] && !cart["frame"]) {
                this.updating = false;
                return false;
            }

            this.cartStore.addToCart(this.order, cart);

            if (this.cartStore.getAmount() === 0) {
                this.$Progress.start();

                return this.cartStore
                    .handleCheckout()
                    .then(() => {
                        if (cart["frame"]) {
                            this.orderStore.updateFrame(
                                this.order.id,
                                cart["frame"]["label"]
                            );
                        }

                        if (cart["size"]) {
                            this.orderStore.updateSize(
                                this.order.id,
                                cart["size"]["label"]
                            );
                        }

                        this.cartStore.clearCart();
                        this.setSelectedAsCurrent();
                        this.updateFrameOptions();
                        this.$Progress.finish();
                        this.$toast.add(
                            {
                                severity: "success",
                                summary: "Success",
                                detail: "Your order has been updated!",
                                life: 3500,
                            }
                        );
                    })
                    .catch((e) => {
                        this.$Progress.fail();

                        this.$toast.add({
                            severity: "error",
                            summary: "Error",
                            detail: e?.response?.data || "There was a problem with your request. Please try again later.",
                            life: 5000,
                        });

                        if (e?.response?.status >= 500) {
                            Sentry.captureException(e);
                        } else if (e?.response?.status >= 400 && e?.response?.status < 500) {
                            this.authStore.logOut().then(() => {
                                this.$router.push({
                                    name: "login",
                                    query: {
                                        redirect: this.$route.fullPath,
                                    },
                                });
                            });
                        }
                    })
                    .finally(() => {
                        this.updating = false;
                    });
            } else {
                this.$Progress.start();
                return this.cartStore
                    .handleCheckout()
                    .then((res) => {
                        this.$Progress.finish();
                        window.location.replace(res.data);
                        // alert(JSON.stringify(res.data));
                    })
                    .finally(() => {
                        this.updating = false;
                    });
            }
        },
        setSelectedAsCurrent() {
            this.frame_options.forEach(elm => {
                elm.current = elm.selected;
            });

            this.size_options.forEach(elm => {
                elm.current = elm.selected;
            });
        },
        formatSize(size) {
            if (!sizeIsPortrait(this.order.orderSize)) {
                return sizeToLandscape(size);
            }

            return size;
        },
    },
};
</script>

<style scoped lang="scss">
.line-item-upgrade-section {
    width: 100%;
    text-align: left;
    margin-top: 8px;
    font-family: Helvetica, Inter, Roboto, Arimo, "Nimbus Sans", sans-serif;

    .col {
        padding-left: 6px;
        padding-right: 6px;
    }

    h2 {
        font-size: 24px;
        line-height: 32px;
    }

    .subtitle {
        font-size: 14px;
        line-height: 20px;
        color: #8d8b88;
    }

    .upgrade-controls {
        padding: 0 0 24px 0;
        margin: 3px 0;

        h3 {
            margin: 16px 0 8px;
            font-size: 14px;
            line-height: 20px;
            color: #363636;
        }

        a.option {
            text-align: center;
            color: #392f5a;
            background-color: #EBEAEE;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            display: block;
            padding: 8px;
            outline: 1px solid #DAD8E0;
            border-radius: 4px;

            &:hover {
                text-decoration: none;
            }

            &.selected {
                outline: 2px solid #392F5A;
            }

            &.disabled {
                cursor: default;
                background: lighten(#EBEAEE, 10%);
                outline-color: lighten(#8d8b88, 2%);
                color: lighten(#9084b5, 2%);
            }

            p {
                margin: 5px 0;
            }
        }

        .upgrade-submit {
            margin-top: 26px;

            a.btn {
                width: 100%;
                font-weight: bold;
            }
        }
    }
}
</style>
