<template>
    <div class="content right_side action_block visible" v-if="visible">
        <PhotoReplacementNotice
            v-if="showPhotoReplacementNotice"
            :order="order"
        ></PhotoReplacementNotice>
        <ReviewControls
            v-if="!showPhotoReplacementNotice"
            :order="order"
        ></ReviewControls>

        <div class="row w-100">
            <div class="col-md-6">
                <div class="products_images_slider">
                    <ImageSlider :slides="order.artistImages"></ImageSlider>
                </div>
            </div>
            <div class="col-md-6">
                <LineItemUpgradeSection :order="order"></LineItemUpgradeSection>
            </div>
        </div>
    </div>
</template>

<script>
import ImageSlider from "@/components/ImageSlider.vue";
import ReviewControls from "@/components/review-controls/ReviewControls.vue";
import PhotoReplacementNotice from "@/components/photo-replacement/PhotoReplacementNotice.vue";
import LineItemUpgradeSection from "@/components/upgrade-controls/LineItemUpgradeSection.vue";
import { useOrderStore } from "@/stores/order";
import * as Sentry from "@sentry/vue";

export default {
    name: "StepsController",
    components: {
        ImageSlider,
        ReviewControls,
        PhotoReplacementNotice,
        LineItemUpgradeSection,
    },
    props: ["update", "order"],
    data() {
        return {
            orderStore: useOrderStore(),
        };
    },
    mounted() {
        if (
            this.order.egleStatus !== "On Hold" &&
            this.step >= 3 &&
            this.order.artistImages.length === 0
        ) {
            Sentry.captureMessage(
                "No artist images in line item " + this.order.id
            );
        }
    },
    computed: {
        visible() {
            return this.orderStore.selectedProduct === this.order.id;
        },
        step() {
            return this.orderStore.getOrderStep(this.order.id);
        },
        showPhotoReplacementNotice() {
            return (
                (this.order.allowFreePhotoChange ||
                    this.order.allowPaidPhotoChange ||
                    this.order.paidPhotoChangesAllowed > 0) &&
                this.step < 4
            );
        },
    },

    methods: {},
};
</script>
