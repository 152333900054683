<template>
    <div class="w-100">
        <div class="review-controls" v-if="order.egleStatus !== 'On Hold'">
            <!-- Steps < 3 -->
            <div class="row" v-if="step < 3">
                <div class="col">
                    <div class="approval-message text-center">
                        Thank you for submitting your order. We are working on
                        it and we'll get back to you shortly.
                    </div>
                </div>
            </div>

            <!-- Step 3 -->
            <div v-if="step === 3">
                <div v-if="order.requiresPayment" class="row requires-payment">
                    <div class="col">
                        <div class="row">
                            <div class="col col-1">
                                <div class="pl-2"><span class="thumbsup-icon">👍</span></div>
                            </div>
                            <div class="col col-11">
                                <div class="approval-message">
                                    <h6>Your artwork is ready for approval!</h6>
                                    <p>Please review and approve the completed portrait while settling the remaining balance, as per our agreement, to proceed with delivery.</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="table-wrapper">
                                    <table>
                                        <tr>
                                            <td>Subtotal</td>
                                            <td><b>{{ subtotal }}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Paid 10%</td>
                                            <td><b>{{ paid }}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Remaining amount</td>
                                            <td><b>{{ remaining }}</b></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                           <div class="col text-right">
                               <a
                                   @click.prevent="requestingRevision = true"
                                   class="btn btn-sm btn-tmr-tertiary revision-button w-auto mr-2"
                                   :class="{ opened: requestingRevision }"
                               >Request Revision</a
                               >
                               <a
                                   @click.prevent="payAndApprove"
                                   data-cy="pay-and-approve"
                                   class="btn btn-sm btn-tmr approval-button w-auto mr-2"
                                   :class="{ opened: approvalInProgress }"
                                   style="display: inline-block"
                               >Approve &amp; Pay</a
                               >
                           </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-else>
                    <div class="col-xl-7 first-column">
                        <div class="approval-message">
                            <span class="thumbsup-icon">👍</span> Your artwork is
                            ready for approval!
                        </div>
                    </div>
                    <div class="col-xl-5 second-column">
                        <div class="row">
                            <div class="col-6">
                                <a
                                    @click.prevent="requestingRevision = true"
                                    class="btn btn-sm btn-tmr-tertiary revision-button"
                                    :class="{ opened: requestingRevision }"
                                >Request Revision</a
                                >
                            </div>
                            <div class="col-6">
                                <a
                                    @click.prevent="approvalInProgress = true"
                                    class="btn btn-sm btn-tmr approval-button"
                                    :class="{ opened: approvalInProgress }"
                                >Approve Design</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Step 3.5 -->
            <div class="row" v-if="step === 3.5">
                <div class="col">
                    <div class="approval-message text-center">
                        Thank you for your feedback. We’ll let you know when
                        your updated art is ready for approval.
                    </div>
                </div>
            </div>

            <!-- Step 4 -->
            <div class="row" v-if="step === 4">
                <div class="col" v-if="!order.itemIsDigital">
                    <div class="approval-message text-center">
                        Your artwork has been approved. Thank you! We’ll let you
                        know when it’s sent to print.
                    </div>
                </div>
                <div
                    class="col col-md-8 offset-md-2"
                    v-else
                >
                    <div class="px-3">
                        <a
                            :href="
                                        digitalDownloadLink
                                    "
                            v-if="
                                        digitalDownloadLink
                                    "
                            download
                            class="btn btn-tmr button-digital-download"
                            target="_blank"
                            >Download digital portrait</a
                        >
                    </div>
                </div>
            </div>

            <!-- Step 5 -->
            <div class="row" v-if="step === 5">
                <div class="col" v-if="!order.itemIsDigital">
                    <div class="approval-message text-center">
                        Your order has been sent to print and a tracking number
                        will be sent to you once available!
                    </div>
                </div>
                <div
                    class="col col-md-8 offset-md-2"
                    v-else
                >
                    <div class="px-3 text-center">
                        You can download your portrait here<br />
                        <a
                            :href="
                                        digitalDownloadLink
                                    "
                            v-if="
                                        digitalDownloadLink
                                    "
                            download
                            class="btn btn-tmr button-digital-download"
                            target="_blank"
                            >Download digital portrait</a
                        >
                    </div>
                </div>
            </div>

            <!-- Step 6 -->
            <div class="row" v-if="step === 6">
                <div class="col">
                    <div class="approval-message text-center">
                        <div
                            class="row"
                            v-if="!order.itemIsDigital"
                        >
                            <div class="col">
                                You order has been shipped! You can find order’s
                                tracking number here:
                            </div>
                        </div>
                        <div
                            class="row"
                            v-if="!order.itemIsDigital"
                        >
                            <div class="col">
                                <a
                                    :href="
                                        orderStore.order_data.customer_data
                                            .trackingURL
                                    "
                                    target="_blank"
                                    class="btn btn-tmr button-tracking-number"
                                    >Get tracking number</a
                                >
                            </div>
                            <div class="col">
                                <a
                                    :href="
                                        digitalDownloadLink
                                    "
                                    v-if="
                                        digitalDownloadLink
                                    "
                                    download
                                    class="btn btn-tmr button-digital-download"
                                    target="_blank"
                                    >Download digital portrait</a
                                >
                            </div>
                        </div>
                        <div
                            class="row"
                            v-if="order.itemIsDigital"
                        >
                            <div class="col">
                                <div class="px-3">
                                    <a
                                        :href="
                                        digitalDownloadLink
                                    "
                                        v-if="
                                        digitalDownloadLink
                                    "
                                        download
                                        class="btn btn-tmr button-digital-download"
                                        target="_blank"
                                        >Download digital portrait</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Step 7 -->
            <div class="row" v-if="step === 7">
                <div class="col">
                    <div class="approval-message text-center">
                        <div class="row" v-if="!order.itemIsDigital">
                            <div class="col">
                                Congrats!<br />
                                You order has been delivered!
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <a
                                    :href="
                                        digitalDownloadLink
                                    "
                                    v-if="
                                        digitalDownloadLink
                                    "
                                    download
                                    class="btn btn-tmr button-digital-download"
                                    target="_blank"
                                    >Download digital portrait</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            &nbsp;
        </div>
    </div>
    <RevisionModal
        v-if="requestingRevision"
        @close="requestingRevision = false"
        :item="order.product"
    ></RevisionModal>
    <ApprovalModal
        v-if="approvalInProgress"
        @close="approvalInProgress = false"
        :item="order.product"
    ></ApprovalModal>
</template>

<script>
import RevisionModal from "@/components/review-controls/RevisionModal.vue";
import ApprovalModal from "@/components/review-controls/ApprovalModal.vue";
import { useOrderStore } from "@/stores/order";
import { currencySymbol } from "@/utilities/currency";
import * as Sentry from "@sentry/vue";

export default {
    name: "ReviewControls",
    props: ["order"],
    components: { RevisionModal, ApprovalModal },
    data() {
        return {
            requestingRevision: false,
            approvalInProgress: false,
            orderStore: useOrderStore(),
        };
    },
    computed: {
        subtotal() {
            return currencySymbol("GBP") + (parseFloat(this.order.orderPrice) * 10).toFixed(2);
        },
        paid() {
            return currencySymbol("GBP") + parseFloat(this.order.orderPrice).toFixed(2);
        },
        remaining() {
            return currencySymbol("GBP") + (parseFloat(this.order.orderPrice) * 9).toFixed(2);
        },
        step() {
            return this.orderStore.getOrderStep(this.order.id);
        },
        digitalDownloadLink() {
            return this.order.digitalDownloads || this.orderStore.order_data.customer_data.digitalImageURL || null;
        },
    },
    methods: {
        payAndApprove() {
            this.$Progress.start();

            return this.orderStore.startPaidApproval().then(res => {
                this.$Progress.finish();
                window.location.replace(res.data);
            }).catch(e => {
                this.$Progress.fail();

                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: e?.response?.data || "There was a server error loading your order, please try again later!",
                    life: 5000,
                });

                if (e?.response?.status >= 500) {
                    Sentry.captureException(e);
                } else {
                    this.authStore.logOut().then(() => {
                        this.$router.push({
                            name: "login",
                            query: {
                                redirect: this.$route.fullPath,
                            },
                        });
                    });
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.review-controls {
    font-family: Helvetica, Inter, Roboto, Arimo, "Nimbus Sans", sans-serif;
    background: #f8f6f3;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.requires-payment {
    .approval-message {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #8D8B88;

        h6 {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #363636;
        }
    }

    .table-wrapper {
        text-align: right;
        font-size: 12px;
        line-height: 16px;
        margin: 10px;
        padding: 10px 0;
        border-top: 1px solid #dad1c9;
        border-bottom: 1px solid #dad1c9;

        @media (min-width: 769px) {
            border-bottom: 0px;
        }

        table {
            display: inline-table;

            td {
                padding: 3px 10px;
            }
        }
    }
}

.approval-message {
    padding: 0 8px;
    font-weight: bold;

    @media (max-width: 768px) {
        padding: 0 20px;
    }
}

a.btn {
    font-weight: bold;
    font-size: 0.74rem;
    width: 100%;
    margin-top: 8px;
}

.second-column {
    text-align: right;

    > .row {
        margin-right: 5px;

        @media (max-width: 1200px) {
            margin-left: 5px;
        }

        .col-6 {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

.thumbsup-icon {
    font-size: 24px;
    line-height: 24px;
    height: auto;
    padding: 10px;
    background: transparent;
    border-radius: 3px;
    border: 1px solid #ede5de;
    vertical-align: middle;
    margin-right: 10px;
    display: inline-block;

    @media (max-width: 768px) {
        width: 100%;
        display: block;
        text-align: center;
        margin-bottom: 10px;
    }
}
</style>
